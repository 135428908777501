import request from '@/plugins/axios';

// 分享图片列表
export function shareLinkPage(query) {

  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve(JSON.parse('{"records":[{"id":2,"agencyId":1,"url":"/download/shop/resourceInfo/images/20220402121708780LfbW.jpg","createTime":"2022-07-21 14:48:00","disable":0}],"total":1,"size":40,"current":1,"orders":[],"optimizeCountSql":true,"hitCount":false,"countId":null,"maxLimit":null,"searchCount":true,"pages":1}'));
  //   }, 321);
  // });


  return request({
    url: 'shop-mobile/shareLink/page',
    method: 'get',
    params: query
  });
}

// 生成分享图片
export function getByIdImg(params) {

  // return new Promise(resolve => {
  //   setTimeout(() => {
  //     resolve({data: '/download/shop/resourceInfo/images/20220402121609331gRqI.jpg'});
  //   }, 321)
  // })

  return request({
    url: 'shop-mobile/shareLink/getByIdImg',
    method: 'get',
    headers: { getAllResponse: true },
    params,
  });
}
