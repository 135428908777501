<template>
  <page-container>
    <div class="back-item" @click="$router.back()">
      <van-icon name="arrow-left"/>
      返回
    </div>
    <div v-if="isShowDownloadBtn" class="download-item" @click="download">
      下载
      <van-icon name="down"/>
    </div>

    <van-notice-bar
        mode="closeable"
        wrapable
        :scrollable="false"
        style="position: absolute;z-index: 4000;right: 0;left: 0;bottom: 0;"
    >
      如果下载按钮点击无效，请长按图片进行下载。
    </van-notice-bar>

    <van-image-preview
        class-name="share-link-view"
        v-model="show"
        :async-close="true"
        :show-index="true"
        :images="images"
        @change="onChange"
    >
    </van-image-preview>

    <van-overlay
        :show="!isShowDownloadBtn"
        z-index="9999"
    >
      <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
        <van-loading size="24px" color="#fff">分享图片生成中...</van-loading>
      </div>
    </van-overlay>
  </page-container>
</template>

<script>
import { ImagePreview, Toast } from 'vant';
import { getByIdImg, shareLinkPage } from '@/api/shareLink';

function downloadBase64Image(base64, name) {
  const blob = base64ToBlob(base64); // 转换为 blob

  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, name);
  } else {
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', name + '.png');
    document.body.appendChild(link);
    link.click();
  }

  function base64ToBlob(base64) {
    let arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}


const MAX_LENGTH = 40;

export default {
  name: 'shareLink',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      show: true,
      imgList: [],
      viewIndex: 0,
    };
  },
  created() {
    shareLinkPage({
      current: 1,
      size: MAX_LENGTH,
    }).then(res => {
      let list = Array.isArray(res?.records) ? res?.records : [];
      list = list.filter(e => e.disable === 0);
      this.imgList = list;//.slice(0, 1);

      if (this.imgList.length)
        this.onChange(0);
      else {
        this.$router.back();
        setTimeout(() => {
          Toast('暂无分享图片');
        }, 300);
      }
    });

  },
  computed: {
    isShowDownloadBtn() {
      return !!this.imgList.length && this.imgList[this.viewIndex].isInit === true;
    },
    images() {
      return this.imgList.map(e => e.url);
    },
  },
  methods: {
    onChange(index) {
      this.viewIndex = index;
      const item = this.imgList[index];
      if (!item.isInit) {
        this.$set(item, 'isInit', 'onInit');
        getByIdImg({ id: item.id }).then(res => {
          this.$set(item, 'isInit', true);
          item.url = res.data;
        });
      }

    },
    download() {
      const base64 = this.imgList[this.viewIndex].url;
      downloadBase64Image(base64, '分享图片');
    },

  },
};
</script>

<style lang="less" scoped>
.back-item, .download-item {
  position: absolute;
  z-index: 3000;
  top: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 38px;
  font-size: 16px;
  color: #fff;
  background-color: #181818;
  border-radius: 16px;

  .van-icon-arrow-left {
    margin-right: 4px;
  }
}

.back-item {
  left: 8px;
}

.download-item {
  right: 8px;
}

</style>
